import { useState } from 'react';
import '../styles/Home.css';
import YICUnav from '.././YICU-Nav';

const YICU = () => {

  return (




    <div className="container">
      <YICUnav ></YICUnav>
      <div>
        <p className="center-align"><img src="/images/YICU/2025/YICU2025.png" alt="YICU 2025" width="200" height="100" className="rounded center-align"></img></p>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h3 className="center-align"><b>For A Better Tomorrow (FBT)</b> is proud to accept nominations and applications for 2025 Why I See You (YICU) Service Awards, as part of its mission to recognize and celebrate young adults’ leadership skills and contributions to the broader community.</h3>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="center-align c737373"><b >Program Overview</b></h4>
      </div>

      <div>
        <h4 className="center-align c737373">FBT collaborates with other community partners to give special recognition to young adults who have made their mark on our community. FBT’s YICU Service Award recognizes and celebrates community service and leadership in McLean County. Proceeds from the event will benefit the diverse efforts supported by FBT, including Youth Global Citizen (YGC).</h4>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="cFF5833"><b >Note : Last date for nominations is September 15, 2025. Applications are due September 15, 2025.</b></h4>
      </div>

      <p >&nbsp;&nbsp;</p>
      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-md-2 ">

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <p >&nbsp;</p>
              <h3 ><b >Nominations</b></h3>
              <h4 className="cF8F8F8"><u >Eligibility</u></h4>
              <h6 className="cFFFFFF"><i >Nominate a young person ages 12-22, who resides in Mclean County.</i></h6>
              <h4 className="cF8F8F8"><u >Required</u></h4>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicu-nom"><b><span className="cFFFFFF">Nomination Form</span></b></a></i></h6>
            </div>

          </div>

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <h3 ><b >Individual</b></h3>
              <h4 className="cF8F8F8"><u >Eligibility</u></h4>
              <h6 className="cFFFFFF"><i >Age - 12 to 22</i></h6>
              <h6 className="cFFFFFF"><i >Resident of McLean County</i></h6>
              <h4 className="cF8F8F8"><u >Required</u></h4>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicu-app"><b><span className="cFFFFFF">Application Form</span></b></a></i></h6>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicumed"><b><span className="cFFFFFF">Media Release Form</span></b></a></i></h6>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicurefer"><b><span className="cFFFFFF">Reference Form</span></b></a></i></h6>
            </div>

          </div>

        </div>

        <div className="col-md-2 ">

        </div>

      </div>

      <div className="row">

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <h3 ><b >Team</b></h3>
              <p >&nbsp;</p>
              <h4 className="cF8F8F8"><u >Eligibility</u></h4>
              <h6 className="cFFFFFF"><i >Serving McLean County</i></h6>
              <h4 className="cF8F8F8"><u >Required</u></h4>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicuteam"><b><span className="cFFFFFF">Nomination Form</span></b></a></i></h6>
              <h6 className="cF8F8F8"><i ><a href="https://tinyurl.com/yicumed"><b><span className="cFFFFFF">Media Release Form</span></b></a></i></h6>
            </div>

          </div>

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <h3 ><b >Advocate</b></h3>
              <p >&nbsp;</p>
              <h4 className="cF8F8F8"><u >Eligibility</u></h4>
              <h6 className="cFFFFFF"><i >Resident of McLean County</i></h6>
              <h4 className="cF8F8F8"><u >Required</u></h4>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicuadvocate"><b><span className="cFFFFFF">Nomination Form</span></b></a></i></h6>
            </div>

          </div>

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <h3 ><b >Mentor</b></h3>
              <p >&nbsp;</p>
              <h4 className="cF8F8F8"><u >Eligibility</u></h4>
              <h6 className="cFFFFFF"><i >Resident of McLean County</i></h6>
              <h4 className="cF8F8F8"><u >Required</u></h4>
              <h6 className="cFFFFFF"><i ><a href="https://tinyurl.com/yicumentor"><b><span className="cFFFFFF">Nomination Form</span></b></a></i></h6>
            </div>

          </div>

        </div>

      </div>

      <div className="row">

        <div className="col-md-2 ">

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <p >&nbsp;</p>
              <h3 ><b >Selection Criteria</b></h3>
              <h6 className="c646063;">Demonstrates a strong desire to become a better leader in all aspects of life.</h6>
              <h6 className="c646063;">Demonstrates the ability to lead and inspire others.</h6>
              <h6 className="c646063;">Passion for serving others.</h6>
            </div>

          </div>

        </div>

        <div className="col-md-4">

          <div className="div-img-text">

            <img src="/images/base/BlueSquare.png" alt="" width="100%" className="rounded-circle"></img>
            <div className="text-on-image">

              <p >&nbsp;</p>
              <h3 ><b >Event Info</b></h3>
              <h6 className="c646063;">FBT’s YICU Service Awards Celebration will held at Jon Astroth Auditorium, Heartland Community College on Sunday, November 9 at Noon.</h6>
              <h6 className="c646063;">Ten winners will be announced from the nominees, three from each age category and one at large.</h6>
            </div>

          </div>

        </div>

        <div className="col-md-2 ">

        </div>

      </div>

      <p >&nbsp;&nbsp;</p>
      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="center-align"><b ><i >"FBT will make a $250 donation towards a non-profit in honor of each award winner."</i></b></h4>
      </div>

      <div>
        <h3 className="center-align redBg"><b >Be a sponsor for YICU Award!! Please contact us at <a href="mailto:contactus@forbettertomorrow.org">contactus@forbettertomorrow.org</a></b></h3>
      </div>

    </div>



  );
}
export default YICU;

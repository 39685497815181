import { useState } from 'react';
import '../styles/Home.css';

const KiteFest = () => {

  return (

    <div className="container">

      <div>
        <h2 className="fbt-title"><img src="/images/base/Logo.jpg" alt="" width="40" height="40" className="rounded" />  for a <span className="fbt-title-sub">better</span> tomorrow </h2>
      </div>

      <div>
        <h1 className="center-align"><b >Youth Volunteer Corps (YVC)</b></h1>
      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="justify c737373">At For A Better Tomorrow, we empower youth and adults to create meaningful change through volunteerism. Our Youth Volunteer Corps (YVC) organizes monthly events that support various nonprofits and community initiatives.</h4>
        <h4 className="justify c737373">By volunteering with YVC, you'll have the opportunity to lead and participate in impactful activities that strengthen our community while building connections and fostering personal growth.</h4>
        <h4 className="justify c737373">In 2024, YVC partnered with organizations like Midwest Food Bank, Miller Park Zoological Society, McLean County History Museum, Ecology Action Center, Parklands Foundation, and more. Volunteers also supported For A Better Tomorrow s initiatives, including the FBT Telethon, Kite Fest 2024, and Grant Review Committee.</h4>
        <p >&nbsp;&nbsp;</p>
      </div>

      <div>
        <h4 className="center-align"><b >Volunteer Requirements:</b></h4>
        <ul>
          <li ><h4 className="c737373">Unless specified, volunteers must be at least 10 years old.</h4></li>
          <li ><h4 className="c737373">Younger volunteers must be accompanied by an adult, preferably a parent or guardian.</h4></li>
        </ul>

      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="center-align"><b >Stay Updated:</b></h4>
        <h4 className="center-align c737373">Join FBT s <a href="https://chat.whatsapp.com/Bn8JUtw7Yhy3Xq4yoGIQCH">WhatsApp group</a> to stay informed about future monthly volunteer opportunities and make a positive impact in your community.</h4>
        <h4 className="center-align c737373">Got questions or ideas for volunteer projects we should consider? Email us at <a href="mailto:contactus@forbettertomorrow.org">contactus@forbettertomorrow.org</a>.</h4>
        <h4 className="center-align c737373">Be part of a dedicated team committed to giving back and creating a better tomorrow!</h4>
        <p >&nbsp;&nbsp;</p>
      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/Parklands-July.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Partnering with the Parkland Foundation for an invasive plant removal and trail maintenance.</h6>
        </div>

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/MCMH-March.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Helping McLean County Museum of History clean up Museum Square after Easter Egg Hunt.</h6>
        </div>

      </div>

      <div className="row">

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/Midwest-January.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Teamed up with Midwest Food Bank to pack 60 boxes, each filled with 20 lasagna pasta family bags.</h6>
        </div>

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/MCMH-November.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Packed cards and wrote letters for residents in assisted living facilities</h6>
        </div>

      </div>

      <div className="row">

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/EAC-September.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Partnered with the Ecology Action Center (EAC) to help with storm drain stenciling and raise awareness about water pollution.</h6>
        </div>

        <div className="col-sm-6 center-align">
          <img src="/images/YVC/Zoo-march.png" alt="" width="300" height="200" className="rounded" />
          <h6 className="center-align">Assisted Miller Park Zoo with the Stampede event.</h6>
        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

    </div>




  );
}
export default KiteFest;

import { useState } from 'react';
import '../styles/Home.css';

const KiteFest = () => {

  return (




    <div className="container">

      <div>
        <h2 class="center-align"><b>Kite Fest 2025</b></h2>
      </div>

      <div><hr class="hr-line"></hr></div>

      <div>
        <h4 class="center-align c-gray"><span class="c-blue">When:</span> Saturday July 19th, 2025, 11:00 am - 6:00 pm CST</h4>
      </div>

      <div>
        <h4 class="center-align c-gray"><span class="c-blue">Cost:</span> <b>FREE</b> for All.</h4>
      </div>

      <div>
        <h4 class="center-align c-gray"><span class="c-blue">Location:</span> Grove Elementary School, Airport Rd, Normal IL 61761</h4>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 class="center-align c-gray"><b >For a Better Tomorrow presents Kite Fest, an annual fundraising event benefiting local and global communities. This will be our 12th year in a row since starting in 2014. Kite Fest 2025 will be held on Saturday, July 19, 2025 at Grove Elementary School.</b></h4>
      </div>

      <div>
        <h4 class="center-align c-gray"></h4>
      </div>

      <p ></p>
      <div>
        <h4 class="center-align c-gray"><u ></u></h4>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h2 class="center-align"><b ></b></h2>
        <p class="center-align"><img src="/images/flyers/KiteFest2025.jpg" alt="" width="100%" className="rounded" /></p>
        <div><hr class="hr-line"></hr></div>

        <h2 class="fbt-tag-line"> Our mission is to promote social justice by meeting needs in the local community and the world through accountable, sustainable philanthropy and by nurturing future generations of servant leaders. </h2>
      </div>

    </div>



  );
}
export default KiteFest;
